'use strict';

import React from 'react';
import PropTypes from 'prop-types';

class AlertItemContent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showingViewMore: !props.viewingAlert,
            shouldShowViewMore: !props.viewingAlert,
            fullHeight: null
        }
    }

    componentDidMount() {
        const heightOfContainer = this.container.offsetHeight;
        let newState = Object.assign({}, this.state, {
            fullHeight: heightOfContainer
        });

        if (heightOfContainer < 150) {
            newState.showingViewMore = false;
            newState.shouldShowViewMore = false;
        }

        if (this.props.viewMoreCalculated) this.props.viewMoreCalculated(newState.shouldShowViewMore);

        this.setState(newState);
    }

    static getDerivedStateFromProps(props, state) {
        let showing = state.shouldShowViewMore && !props.viewingAlert;
        return {
            showingViewMore: showing
        }
    }

    getMaxHeight() {
        if (!this.state.fullHeight) return 'inherit';
        return this.props.viewingAlert
            ? this.state.fullHeight + 'px'
            : '150px';
    }

    render() {
        let styles = {maxHeight: this.getMaxHeight()};
        return <div ref={el => (this.container = el)} className="react-alerts--alert-content" style={styles}>
            <div dangerouslySetInnerHTML={{__html: this.props.content}}/>
            {this.state.showingViewMore ? <a className="react-alerts--view-more">Read more</a> : null}
        </div>;
    }

}

AlertItemContent
    .propTypes = {
    content: PropTypes.string.isRequired,
    viewingAlert: PropTypes.bool,
    viewMoreCalculated: PropTypes.func
};

AlertItemContent
    .defaultProps = {
    viewingAlert: false,
    viewMoreCalculated: null
};

export default AlertItemContent;