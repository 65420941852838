'use strict';

import AlertRepository from '../Alert/AlertRepository';
import * as ActionTypes from './ActionTypes';

export function openSisp() {
    return {
        type: ActionTypes.OPEN_ALERTS_SISP,
    };
}

export function closeSisp() {
    return {
        type: ActionTypes.CLOSE_ALERTS_SISP,
    };
}

export function loadAlerts(filter, offset) {
    return (dispatch) => {
        dispatch(loadingAlerts());
        AlertRepository.search(filter, offset, 10)
            .then((response) => dispatch(alertsLoaded(response)))
            .catch((e) => dispatch(alertsLoadingFailed(e)));
    };
}

export function viewAlert(origin, alertId, markAsOpened) {
    return (dispatch) => {
        dispatch(alertOpened(alertId, origin));
        if (alertId === 0 || markAsOpened) return;
        AlertRepository.open(origin, alertId).catch((e) => dispatch(alertsLoadingFailed(e)));
    };
}

export function dismissAlert(origin, alertId) {
    return (dispatch) => {
        dispatch(dismissingAlert(alertId, origin));
        AlertRepository.dismiss(alertId, origin)
            .then(() => dispatch(alertDismissed(alertId)))
            .catch((e) => dispatch(alertsLoadingFailed(e)));
    };
}

function loadingAlerts() {
    return {
        type: ActionTypes.ALERTS_LOADING,
    };
}

function alertsLoaded(response) {
    return {
        type: ActionTypes.ALERTS_LOADED,
        payload: {
            response: response,
        },
    };
}

function dismissingAlert(alertId, origin) {
    return {
        type: ActionTypes.DISMISSING_ALERT,
        payload: {
            alertId: alertId,
            origin: origin,
        },
    };
}

function alertDismissed(alertId, origin) {
    return {
        type: ActionTypes.ALERT_DISMISSED,
        payload: {
            alertId: alertId,
            origin: origin,
        },
    };
}

function alertOpened(alertId, origin) {
    return {
        type: ActionTypes.VIEW_ALERT,
        payload: {
            alertId: alertId,
            origin: origin,
        },
    };
}

function alertsLoadingFailed(error) {
    return {
        type: ActionTypes.ALERTS_FAILED_TO_LOAD,
        payload: {
            error: error,
        },
    };
}
