'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import ToolTip from "../../../../../CommonComponents/ToolTip/ToolTip";

class AlertItemHeader extends React.Component {

    newLabel() {
        if (!this.props.isNew) return null;
        return <label className="label label-success" style={{marginLeft: '10px'}}>NEW</label>
    }

    render() {
        return <div className="react-alerts--alert-header">
            <div className="react-alerts--alert-category">{this.props.category}</div>
            <div className="react-alerts--alert-sent"><ToolTip tooltip={this.props.tooltipDate}>{this.props.sent}{this.newLabel()}</ToolTip></div>
        </div>;
    }

}

AlertItemHeader.propTypes = {
    sent: PropTypes.string.isRequired,
    tooltipDate: PropTypes.string,
    category: PropTypes.string,
    isNew: PropTypes.bool
};

AlertItemHeader.defaultProps = {
    category: 'Standard',
    isNew: false,
    tooltipDate: ''
};

export default AlertItemHeader;
