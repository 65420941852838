'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Spinner from "../../../../CommonComponents/Spinner/Spinner";

class AlertPaginator extends React.Component {

    paginationButton() {
        if (this.props.totalAvailable === undefined
            || this.props.totalAvailable <= this.props.currentlyDisplayed)
            return null;

        if (this.props.moreLoading) return <div><Spinner/></div>;

        return <button className="btn btn-primary" onClick={this.props.loadMore}>... More ...</button>;
    }

    render() {
        return <div className="react-alert--paginator">
            {this.paginationButton()}
        </div>;
    }

}

AlertPaginator.propTypes = {
    currentlyDisplayed: PropTypes.number.isRequired,
    totalAvailable: PropTypes.number.isRequired,
    loadMore: PropTypes.func.isRequired,
    moreLoading: PropTypes.bool
};

AlertPaginator.defaultProps = {
    moreLoading: false
};

export default AlertPaginator;