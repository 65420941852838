'use strict';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import rootReducer from './rootReducer';
import ThunkMiddleware from 'redux-thunk';

import './alerts.scss';
import Alerts from "./Alerts";
import {openSisp} from "./Actions/Actions";

const store = createStore(rootReducer, applyMiddleware(ThunkMiddleware));

$('.js-show-alerts-sisp').on('click', function(e) {
    e.preventDefault();
    store.dispatch(openSisp());
});

const el = document.getElementById('AlertsSISP');

if (el) {
    const root = createRoot(el);
    root.render(
        <Provider store={store}>
            <Alerts />
        </Provider>
    );
}

store.subscribe(() => {
    if (!store.getState().main.alerts) return;
    const alerts = store.getState().main.alerts.map(a => a.isNew ? 1 : 0);
    const numberOfAlerts = alerts.length > 0 ? alerts.reduce((total, num) => total + num) : 0;
    if (numberOfAlerts > 0) {
        const badges = document.getElementsByClassName('js-alert-count-badge');
        for (let i = 0; i < badges.length; i++) {
            const root = createRoot(badges[i]);
            root.render(
                <span className="badge badge-danger notification-badge">{numberOfAlerts}</span>
            );
        }
    }
});
